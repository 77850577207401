import React from "react"
import { Button } from "@material-ui/core"
import * as styles from "./Info.module.scss"
import { SuccessIcon } from "../../../images/svg/Success"
import { ErrorIcon } from "../../../images/svg/Error"

type Props = {
  onClick: () => void
  isSuccess: boolean
}

const Info = ({ onClick, isSuccess }: Props) => (
  <div className={styles.info}>
    <p className={styles.text}>
      {isSuccess ? (
        <>
          <div className={styles.iconContainer}>
            <SuccessIcon />
          </div>
          Заявка по по программе &quot;Приведи&nbsp;друга&quot; получена. Обратитесь в службу
          поддержки
        </>
      ) : (
        <>
          <div className={styles.iconContainer}>
            <ErrorIcon />
          </div>
          Корректная заявка по программе &quot;Приведи&nbsp;друга&quot; не найдена.
        </>
      )}
    </p>
    <div className={styles.btnContainer}>
      <Button onClick={onClick} color="primary">
        Назад
      </Button>
    </div>
  </div>
)

export default Info
