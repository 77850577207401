import React from "react"

export const SuccessIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
    <g>
      <g>
        <g>
          <g>
            <path
              fill="#73ce09"
              d="M27.5 51C40.479 51 51 40.479 51 27.5S40.479 4 27.5 4 4 14.521 4 27.5 14.521 51 27.5 51z"
            />
          </g>
          <g>
            <path
              fill="#fff"
              d="M25.118 38L16 28.943l3.25-3.225 5.868 5.81L36.75 20 40 23.225 25.118 38z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
