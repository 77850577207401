import React from "react"

export const ErrorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
    <g>
      <g>
        <g>
          <g />
          <g>
            <path
              fill="#ff4b5f"
              d="M28.003 51.337c12.887 0 23.334-10.447 23.334-23.334 0-12.886-10.447-23.333-23.334-23.333C15.117 4.67 4.67 15.117 4.67 28.003c0 12.887 10.447 23.334 23.333 23.334z"
            />
          </g>
          <g>
            s
            <path
              fill="#fff"
              d="M38.4 20.9l-3.29-3.29-7.105 7.093L20.9 17.61l-3.29 3.29 7.093 7.105-7.093 7.105 3.29 3.29 7.105-7.093L35.11 38.4l3.29-3.29-7.093-7.105L38.4 20.9z"
            />
          </g>
        </g>
      </g>
    </g>
    s
  </svg>
)
