import React from "react"
import { PageProps, graphql } from "gatsby"
import { Layout } from "../../components/Layout"
import { Form } from "../../components/Invitefriend/Form/Form"
import { PageData } from "../../interfaces/pageProps"
import { getPageData } from "../../helpers/getPageData"
import { NoIndex } from "../../components/NoIndex"

export default function InvitefriendCheck({ data }: PageProps<PageData>) {
  const { seoData } = getPageData(data)
  return (
    <Layout
      seoData={seoData}
      noFooter
      headerProps={{
        PhoneProps: {
          phone: "8 800 100-10-20",
        },
      }}
    >
      <NoIndex />
      <Form />
    </Layout>
  )
}

export const query = graphql`
  query {
    pageDataJson(pageName: { eq: "invitefriend-check" }) {
      seoData {
        title
        description
      }
    }
  }
`
