import React, { useState } from "react"
import Container from "@ecom/ui/components/Container"
import FormLanding from "@ecom/ui/components/FormLanding"
import PhoneField from "@ecom/ui/components/FormLanding/fields/PhoneField"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import sendRequest from "@ecom/ui/utils/sendRequest"
import formatToRequest from "@ecom/ui/helpers/formatToRequest"
import TextField from "@ecom/ui/components/FormLanding/fields/TextField"
import { useTransition, animated } from "react-spring"
import requiredValidator from "@ecom/ui/components/FormLanding/validators/required"
import * as styles from "./form.module.scss"
import Info from "../Info/Info"
import { handleClickForm } from "../../../helpers/WatcherDL/utils/handleClickForm"
import { DLGAFormEvent } from "../../../helpers/WatcherDL/DLEvents"

type InfoStatus = {
  show: boolean
  isSuccess: boolean
}

interface FormValues {
  url: string
  phone: string
}

const fieldsType = {
  url: "url",
  phone: "phone",
}

export function Form() {
  const [infoStatus, setInfoStatus] = useState<InfoStatus>({ show: false, isSuccess: false })

  const transitions = useTransition(infoStatus.show, {
    from: { transform: "translate3d(-364px,0,0)" },
    enter: { transform: "translate3d(0,0,0)" },
    leave: { transform: "translate3d(-364px,0,0)" },
  })

  const onSubmit = (values: FormValues) => {
    pushToDataLayer({
      event: "GAFormEvent",
      name: "Продолжить",
      placement: "appl_halva",
      eventAction: "passportForm",
      eventCategory: "sendForm",
    })
    const valuesToRequest = formatToRequest(values, fieldsType)
    const URL = "/v2/app/public/halva/invite"
    sendRequest(`${process.env.GATSBY_APP_URL_API}${URL}`, {
      ...valuesToRequest,
    })
      .then((res: { exist: boolean }) => {
        pushToDataLayer({ event: "applicationSend", result: "success" })
        DLGAFormEvent({
          eventCategory: "sendForm",
          eventAction: "personalForm",
          placement: "main",
        })
        setInfoStatus({ show: true, isSuccess: res.exist })
      })
      .catch(() => {
        pushToDataLayer({ event: "applicationSend", result: "error" })
        setInfoStatus({ show: true, isSuccess: false })
      })
  }

  const onChangeFields = {
    phone: () => {
      handleClickForm()
    },
    url: () => {
      handleClickForm()
    },
  }

  return (
    <section className="gradient-bottom" id="form">
      <Container className={styles.container}>
        <div className={styles.contentWrapper}>
          {transitions((style, item) =>
            item ? (
              <animated.div style={style} className={styles.content}>
                <Info
                  onClick={() => setInfoStatus({ show: false, isSuccess: false })}
                  isSuccess={infoStatus.isSuccess}
                />
              </animated.div>
            ) : (
              <animated.div style={style} className={styles.content}>
                <FormLanding onSubmit={onSubmit} onChangeFields={onChangeFields}>
                  <h1 className={styles.head}>
                    Проверка ссылки по&nbsp;акции
                    <br />
                    &quot;Приведи друга &quot;
                  </h1>
                  <TextField validate={requiredValidator} fullWidth name="url" label="Ссылка" />
                  <PhoneField name="phone" label="Телефон" />
                  <div className={styles.btnOuter}>
                    <SubmitButton className={styles.btn}>Проверить</SubmitButton>
                  </div>
                </FormLanding>
              </animated.div>
            )
          )}
        </div>
      </Container>
    </section>
  )
}
